<template>
  <div>
    <Settings
      class="col-12"
      :entity-type="'menu'"
      :entity-label="labels.menu"
      :entity-path-name="labels.path_name"
      :has-tags="false"
      v-bind:required-fields="requiredFields" />
  </div>
</template>

<script>
import Settings from "@/views/Dashboard/Common/Settings";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    Settings,
  },
  data() {
    return {
      requiredFields: {
        title: {
          type: "text",
          label: this.labels.title,
        },
        description: {
          type: "textarea",
          label: this.labels.description,
        },
      },
    };
  },

  mounted() {
    this.$store.commit("menu/getMenuForEditor", this.$route.params.id);
  },
  beforeMount() {
    // Redirect if user don't have permissions

    if (!this.isAllowed && this.admin_roles) {
      this.$router.push({ name: "dashboard" });
    }
  },
  computed: {
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
    }),
    ...mapGetters("helper", ["labels"]),
    ...mapState("helper", {
      admin_roles: "admin_roles",
    }),
    ...mapState("blockEditor", {
      singleEntity: "singleEntity",
    }),
    isAllowed() {
      return this.hasPermissions(["super", "web"]);
    },
  },
  watch: {
    admin_roles() {
      if (!this.isAllowed) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>

<style scoped></style>
